import React from "react";
import Layout from "../../components/layout";
import HeaderOne from "../../components/header/header-one";
import StickyHeader from "../../components/header/sticky-header";
import Footer from "../../components/footer";
import ResourceNews from "../../components/resources/resource-news";
import ComingSoon from "../Work/Coming-soon";
const News = () => {
  return (
    <Layout pageTitle="News">
      <HeaderOne />
      <StickyHeader />
      <section className="mhh-bg-image">
      <ResourceNews/>
      <ComingSoon />
      </section>
      <Footer />
    </Layout>
  );
};
export default News